<template>
  <div id="organisation-events">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Organisation Events</h1>
      <h4>{{ organisation.name }}</h4>
      <b-pagination
        v-show="organisationEvents.length > perPage"
        v-model="currentPage"
        :total-rows="organisationEvents.length"
        :per-page="perPage"
        aria-controls="org-events"
        align="right"
      ></b-pagination>
      <b-table
        id="org-events"
        :busy="busy"
        :empty-text="emptyText"
        :items="organisationEvents"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:table-busy>
          <Busy primary text="Loading..." />
        </template>
        <template v-slot:cell(user)="row">
          <router-link
            :to="{ name: 'email-events-withEmail', params: { emailAddress: row.value } }"
            >{{ row.value }}</router-link
          >
        </template>
        <template v-slot:cell(emails)="row">
          <router-link
            :to="{ name: 'email-events-withEmail', params: { emailAddress: row.value } }"
            >{{ row.value }}</router-link
          >
        </template>
        <template v-slot:cell(created)="row">{{ formatDateTimeToSec(row.value) }}</template>
      </b-table>
      <b-pagination
        v-show="organisationEvents.length > perPage"
        v-model="currentPage"
        :total-rows="organisationEvents.length"
        :per-page="perPage"
        aria-controls="org-events"
        align="right"
      ></b-pagination>
    </b-container>
  </div>
</template>

<style lang="sass">
@import "@/styles/common.scss"
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "organisation-events",
  components: { Busy },
  data() {
    return {
      title: "Organisation Events",
      busy: false,
      organisationEvents: [],
      organisation: {},
      perPage: 50,
      currentPage: 1,
      fields: [
        {
          key: "created",
          label: "Created",
          sortable: true
        },
        {
          key: "type",
          label: "Event Type",
          sortable: true
        },
        {
          key: "user",
          label: "User",
          sortable: true,
          formatter: (value) => {
            return value ? value.email : "-";
          }
        },
        {
          key: "hasPhone",
          sortable: true
        },
        {
          key: "emails",
          label: "Shared With",
          formatter: (value) => {
            return value ? value.join(", ").toLowerCase() : "";
          }
        },
        {
          key: "phoneNumber"
        }
      ],
      emptyText: "No events found",
      sortBy: null,
      sortDesc: null
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    formatDateTimeToSec: FormatHelper.formatDateTimeToSec,
    async fetchData() {
      const organisationId = this.$route.params.organisationId;
      this.fetchEvents(organisationId);
      this.fetchOrganisation(organisationId);
    },
    async fetchOrganisation(organisationId) {
      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        Console.log(response);

        if (response.data.status === "success") {
          this.organisation = response.data.organisation;
        } else {
          this.showWarning("There was a problem getting the organisation.");
        }
      } catch {
        this.showWarning("There was a problem getting the organisation.");
      }
    },
    async fetchEvents(organisationId) {
      try {
        this.busy = true;
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}organisations/${organisationId}/events?includeUsers=true`
        );

        Console.log(response);

        if (response.data.status === "success") {
          const userLookup = {};

          this.organisationEvents = [];

          if (response.data.events) {
            if (response.data.users) {
              for (const user of response.data.users) {
                userLookup[user.userId] = user;
              }
            }

            for (const event of response.data.events) {
              event.user = userLookup[event.userId];
            }

            this.organisationEvents = response.data.events;
          }
        } else {
          this.showWarning("There was a problem getting organisation events.");
        }
      } catch (err) {
        Console.error(err);
        this.showWarning("There was a problem getting organisation events.");
      } finally {
        this.busy = false;
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>
